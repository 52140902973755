import React, { useEffect, useMemo, useState } from 'react';
import { graphql } from 'gatsby';
import { builder, Builder, BuilderComponent } from '@builder.io/react';
import loadable from '@loadable/component';
import '../../static/css/funnel.css';
import { FunnelProvider } from '../context/FunnelContext';
import axios from 'axios';
import Microdata from '../components/Microdata/Microdata';

const Helmet = loadable(() => import('react-helmet'));

builder.init(process.env.GATSBY_BUILDER_API_KEY);

const defaultTitle = 'PetLab Co.';
const defaultDescription = 'Supplies, Treats, Snacks & Chews - Join The Pack';

function FunnelTemplate(props) {
  const {
    data,
    path,
    location: { href: funnelUrl },
    '*': tokenQuery
  } = props;
  const funnelContent = data.allBuilderModels.oneFunnel.content;
  const funnelDesign =
    data.allBuilderModels.oneFunnel.data?.design ?? `design1`;
  const productSelectorData = data.funnelPageData?.productSelector;
  const funnelSettings = useMemo(
    () =>
      data.allBuilderModels.oneFunnel.data?.settings ?? {
        initToken: 'none',
        defaultTubIndex: 0
      },
    [data]
  );
  const defaultCategory =
    props.pageContext.category ??
    data.allBuilderModels.oneFunnel.data?.currentCategory ??
    `subscriptions`;
  const defaultTubIndex =
    props.pageContext.tubIndex ?? funnelSettings?.defaultTubIndex ?? 0;
  const defaultProduct =
    defaultCategory === 'subscriptions'
      ? `subscription_${defaultTubIndex}`
      : `onetime_${defaultTubIndex}`;
  const redirectToEcomm = useMemo(
    () => data.allBuilderModels.oneFunnel.data?.redirectToEcomm ?? false,
    [data]
  );
  const redirectToEcommUrl = useMemo(
    () => data.allBuilderModels.oneFunnel.data?.redirectToEcommUrl,
    [data]
  );
  const microdata = useMemo(
    () => data.allBuilderModels.oneFunnel.data?.microdata,
    [data]
  );

  useEffect(() => {
    if (redirectToEcomm) {
      window.location.href =
        redirectToEcommUrl ??
        process.env.GATSBY_ECOM_URL ??
        'https://thepetlabco.com/';
    }
  }, [redirectToEcomm, redirectToEcommUrl]);

  useEffect(() => {
    const geoRedirection = data.allBuilderModels.oneFunnel.data?.geoRedirection;
    if (geoRedirection) {
      axios
        .get(`https://promo.thepetlabco.com/api/geo-location.php`)
        .then(results => {
          geoRedirection.forEach(({ countryCode, url }) => {
            if (countryCode === results.data.countryCode) {
              window.location.href = url;
            }
          });
        });
    }
  }, [data.allBuilderModels.oneFunnel.data?.geoRedirection]);

  const [currentCategory, setCurrentCategory] = useState(defaultCategory);
  const [currentProduct, setCurrentProduct] = useState(defaultProduct);
  const [tubIndex, setTubIndex] = useState(defaultTubIndex);
  const [currentTubIndex, setCurrentTubIndex] = useState(defaultTubIndex);
  const [productImageIndex, setProductImageIndex] = useState(0);
  const [frequency, setFrequency] = useState('1:month');
  const [bumpOfferChecked, setBumpOfferChecked] = useState(false);
  const [tubIndexChecked, setTubIndexChecked] = useState(false);
  const [bumpOfferIndex, setBumpOfferIndex] = useState(0);
  const [currentTub, setCurrentTub] = useState({});
  const [subscriptionList, setSubscriptionList] = useState(null);
  const [userDetails, setUserDetails] = useState(null);
  const [deliveryDate, setDeliveryDate] = useState(null);
  const [calendarDate, setCalendarDate] = useState(null);
  const [loadingCover, setLoadingCover] = useState(true);
  const [showSavingsPopup, setShowSavingsPopup] = useState(false);
  const [submittedToCart, setSubmittedToCart] = useState(false);
  const [currentLayer, setCurrentLayer] = React.useState(`layer-0`);
  const [previousLayer, setPreviousLayer] = React.useState([]);
  const [localCartItems, setLocalCartItems] = React.useState([]);
  const [funnelUpdated, setFunnelUpdated] = React.useState(false);
  const [answers, setAnswers] = React.useState({});
  const [extraObjects, setExtraObjects] = useState({
    ...JSON.parse(productSelectorData?.extra || '{}'),
    is_tub_index_checked: 'no',
    currentCategory
  });

  useEffect(() => {
    const url = typeof window !== 'undefined' ? window.location.search : '';
    const urlParams = new URLSearchParams(url);
    const selection = parseInt(urlParams.get('sel'));
    const options = [1, 2, 3, 4, 5, 6, 7, 8];
    if (selection && options.includes(selection)) {
      setTubIndex(
        selection <= 4 ? parseInt(selection - 1) : parseInt(selection - 5)
      );
      setCurrentTubIndex(
        selection <= 4 ? parseInt(selection - 1) : parseInt(selection - 5)
      );
      setCurrentCategory(selection <= 4 ? 'subscriptions' : 'onetime');
      setCurrentProduct(
        selection <= 4
          ? `subscription_${parseInt(selection - 1)}`
          : `onetime_${parseInt(selection - 5)}`
      );
    }
  }, []);

  const [tokenResultsLoaded, setTokenResultsLoaded] = useState(false);

  useEffect(() => {
    if (!tokenResultsLoaded && tokenQuery && tokenQuery !== ``) {
      const initToken = funnelSettings?.initToken || 'none';
      const endpointApiUrl = funnelSettings?.endpointApiUrl;
      const tokenExpiryUrl =
        funnelSettings?.tokenExpiryUrl ||
        'https://thepetlabco.com/pages/token-expired';

      setExtraObjects(
        Object.assign(extraObjects, {
          tokenQuery: tokenQuery
        })
      );

      const fetchData = async () => {
        switch (initToken) {
          case 'payment-update':
            await axios
              .get(endpointApiUrl, {
                headers: { 'access-token': tokenQuery }
              })
              .then(function(response) {
                const results = response.data;
                setExtraObjects(
                  Object.assign(extraObjects, {
                    user: {
                      ...results
                    }
                  })
                );
              })
              .catch(function() {
                if (!Builder.isEditing) {
                  window.location.href = tokenExpiryUrl;
                }
              });

            return () => {
              setTokenResultsLoaded(true);
            };
          case 'subscription-upgrade':
            await axios
              .get(endpointApiUrl, {
                token: tokenQuery
              })
              .then(function(response) {
                const results = response.data;

                setExtraObjects(
                  Object.assign(extraObjects, {
                    active_subscription_token: tokenQuery,
                    active_subscription_special_offer: 0,
                    active_subscription_discount_type: 'DISCOUNT_25',
                    active_subscription_data: results
                  })
                );
              })
              .catch(function() {
                if (!Builder.isEditing) {
                  window.location.href = tokenExpiryUrl;
                }
              });

            return () => {
              setTokenResultsLoaded(true);
            };
          case 'none':
          default:
            return () => {
              setTokenResultsLoaded(true);
            };
        }
      };

      fetchData();
    }
  }, [
    tokenQuery,
    funnelSettings,
    extraObjects,
    setExtraObjects,
    tokenResultsLoaded,
    setTokenResultsLoaded
  ]);

  const context = {
    ...productSelectorData,
    currentCategory: currentCategory,
    setCurrentCategory: setCurrentCategory,
    currentProduct: currentProduct,
    setCurrentProduct: setCurrentProduct,
    path: path,
    funnelUrl: funnelUrl,
    pageDesign: funnelDesign,
    tubIndex: tubIndex,
    setTubIndex: setTubIndex,
    currentTubIndex: currentTubIndex,
    productImageIndex: productImageIndex,
    setProductImageIndex: setProductImageIndex,
    setCurrentTubIndex: setCurrentTubIndex,
    frequency: frequency,
    setFrequency: setFrequency,
    bumpOfferChecked: bumpOfferChecked,
    setBumpOfferChecked: setBumpOfferChecked,
    bumpOfferIndex: bumpOfferIndex,
    setBumpOfferIndex: setBumpOfferIndex,
    currentTub: currentTub,
    setCurrentTub: setCurrentTub,
    subscriptionList: subscriptionList,
    setSubscriptionList: setSubscriptionList,
    userDetails: userDetails,
    setUserDetails: setUserDetails,
    deliveryDate: deliveryDate,
    setDeliveryDate: setDeliveryDate,
    calendarDate: calendarDate,
    setCalendarDate: setCalendarDate,
    loadingCover: loadingCover,
    setLoadingCover: setLoadingCover,
    showSavingsPopup: showSavingsPopup,
    setShowSavingsPopup: setShowSavingsPopup,
    submittedToCart: submittedToCart,
    setSubmittedToCart: setSubmittedToCart,
    currentLayer: currentLayer,
    setCurrentLayer: setCurrentLayer,
    previousLayer: previousLayer,
    setPreviousLayer: setPreviousLayer,
    answers: answers,
    setAnswers: setAnswers,
    extraObjects: extraObjects,
    setExtraObjects: setExtraObjects,
    tubIndexChecked: tubIndexChecked,
    setTubIndexChecked: setTubIndexChecked,
    localCartItems: localCartItems,
    setLocalCartItems: setLocalCartItems,
    funnelUpdated: funnelUpdated,
    setFunnelUpdated: setFunnelUpdated,
    microdata: microdata
  };

  return (
    <>
      {!redirectToEcomm && (
        <FunnelProvider value={context}>
          <Helmet
            bodyAttributes={{
              class: `petlab-funnel-1 ${funnelDesign}`
            }}
            htmlAttributes={{
              lang: 'en'
            }}
          >
            <title>
              {(funnelContent && funnelContent.data?.siteMeta?.title) ||
                defaultTitle}
            </title>
            <meta
              name="description"
              content={
                (funnelContent && funnelContent.data?.siteMeta?.description) ||
                defaultDescription
              }
            />
            <meta name="funnel-id" content={funnelContent?.id} />
            <meta
              name="product-selector-id"
              content={funnelContent?.data?.products?.id}
            />
            <meta
              name="magic-key"
              content="t54cdW3cbU45TWPRtYGUR4fyBnWHe49jWeuqdRStfAvELctRyfyXLmDteF9Cf9jhPsyzDfkcCK5DNU6Pr87HU8tgNKBDDmeHDFQn"
            />
          </Helmet>
          <Microdata path={props.pageContext.pagePath} />
          {/** name of the model is landing page, change it if you decided to build*/}
          <BuilderComponent
            name="funnel"
            content={funnelContent}
            data={{
              ...context
            }}
          ></BuilderComponent>
        </FunnelProvider>
      )}
    </>
  );
}

export const query = graphql`
  query($funnelId: String) {
    allBuilderModels {
      oneFunnel(
        query: { id: $funnelId }
        options: { cachebust: true, includeRefs: true }
      ) {
        content
        data {
          design
          currentCategory
          settings
          redirectToEcomm
          redirectToEcommUrl
          geoRedirection
          microdata
        }
      }
    }

    funnelPageData(funnelId: { eq: $funnelId }) {
      ...FunnelDataFragment
    }
  }
`;

export default FunnelTemplate;
