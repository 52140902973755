import React, { useContext } from 'react';
import loadable from '@loadable/component';
import FunnelContext from '../../context/FunnelContext';
import { ProductPrice } from '../../utils/productPrice';

const Helmet = loadable(() => import('react-helmet'));

const Microdata = ({ path }) => {
  const {
    currency,
    currentCategory,
    currentProduct,
    funnelUrl = `${process.env.GATSBY_FUNNEL_ROOT_URL_LIVE}${path}`,
    microdata
  } = useContext(FunnelContext);

  if (!microdata?.value) return null;

  const currencyCode = currency?.code || 'USD';

  const discountedPrice = ProductPrice({
    type: `discounted_price`,
    currentCategory: currentCategory,
    currentProduct: currentProduct
  });

  const schema = {
    '@context': 'https://schema.org',
    '@type': 'Product',
    brand: 'PetLab Co.',
    ...microdata.value.data
  };

  Object.assign(schema, {
    offers: {
      '@type': 'Offer',
      availability: 'https://schema.org/InStock',
      price: discountedPrice,
      priceCurrency: currencyCode,
      url: funnelUrl
    }
  });

  return (
    <Helmet>
      <script type="application/ld+json">{JSON.stringify(schema)}</script>
    </Helmet>
  );
};

export default Microdata;
